import { css, SerializedStyles } from '@emotion/react'
import { IStyles } from '../../context/Styles';

export const contactStyles = (styles: IStyles): SerializedStyles => css`
	p {
		line-height: 1.5em;
	}

	a {
		color: ${styles.colors.grey[2]};
	}
`;