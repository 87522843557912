import { css, SerializedStyles } from '@emotion/react'
import { IStyles } from '../../context/Styles';

export const errorPageStyles = (styles: IStyles): SerializedStyles => css`
display: flex;
justify-content: center;
align-items: center;
flex-flow: column;
flex: 1 0 auto;
text-align: center;

h1 {
	margin-top: 0;
}
`;