import { css, SerializedStyles } from '@emotion/react'
import { IStyles } from '../../context/Styles';

export const footerStyles = (styles: IStyles): SerializedStyles => css`
	display: flex;
	width: 100%;
	min-height: 100px;
	margin-top: auto;
`;

export const footerWrapperStyles = (styles: IStyles): SerializedStyles => css`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 20px;
`;

export const footerTextStyles = (styles: IStyles): SerializedStyles => css`
	color: ${styles.colors.black[2]};
`;