import React, { useContext } from "react";
import { StylesContext } from '../../context/StylesContext';
import { ContentPage } from "../../components/ContentPage";
/** @jsxImportSource @emotion/react */
import { contactStyles } from './Contact.styles'

const Contact = () => {
	const { styles } = useContext(StylesContext);

	return (
		<ContentPage title="Contact" navFadeIn={true}>
			{
				<div css={contactStyles(styles)}>
					<h2>As you can probably tell, I'm still getting set up.</h2>

					<p>
						For the moment, the best way to get in touch is to
						<br/>
						shoot me an email at: <a href="mailto:nik@malyaris.com">nik@malyaris.com</a>
					</p>
				</div>
			}
		</ContentPage>
	);
}

export default Contact;
