import React, { useContext } from "react";
import { StylesContext } from '../../context/StylesContext';
import { ContentPage } from "../../components/ContentPage";
/** @jsxImportSource @emotion/react */
import { printStyles } from './Prints.styles'

const Prints = () => {
	const { styles } = useContext(StylesContext);

	return (
		<ContentPage title="Prints" navFadeIn={true}>
			{
				<div css={printStyles(styles)}>
					<h2>
						Coming Soon.
					</h2>
					<p>
						If you'd really like to order something in the meantime,
						<br/>
						please contact me directly.
					</p>
				</div>
			}
		</ContentPage>
	);
}

export default Prints;
