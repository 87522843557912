import React from "react";
import { Routes, Route } from "react-router-dom";
import type { TRouteData } from "../../App";
import { GalleryLandingPage } from "../LandingPage";
import { GalleryController } from "../GalleryController";

// Pages
import { Landing } from "../../pages/Landing";
import { Prints } from "../../pages/Prints";
import { Contact } from "../../pages/Contact";
import { Licensing } from "../../pages/Licensing";
import Error404 from "../ErrorPage/Errors/404";
import Error405 from "../ErrorPage/Errors/405";

// Dev Env Only
import { Test } from "../../pages/Test";
import { Dev } from "../../pages/Dev";

type TRoutesControllerProps = {
	routes: TRouteData[];
};

const RoutesController: React.FunctionComponent<TRoutesControllerProps> = ({
	routes
}) => {
	// TODO: utility function for building the gallery and landing page types
	return (
		<Routes>
			<Route
				path="/"
				element={<Landing />}
			/>

			<Route
				path="/prints"
				element={<Prints />}
			/>

			<Route
				path="/contact"
				element={<Contact />}
			/>

			<Route
				path='/gallery'
				element={
					<GalleryLandingPage
						title='gallery'
						routeData={routes.filter(route => route.routeType === 'gallery')}
						isSecure={false}
					/>
				}
			/>

			<Route
				path='/personal'
				element={
					<GalleryLandingPage
						title='personal'
						routeData={routes.filter(route => route.routeType === 'personal')}
						isSecure={false}
					/>
				}
			/>

			<Route
				path="/gallery/:id"
				element={
					<GalleryController type='gallery' data={routes.filter(route => route.routeType === 'gallery')} isSecure={false} />
				}
			/>

			<Route
				path="/personal/:id"
				element={
					<GalleryController type='personal' data={routes.filter(route => route.routeType === 'personal')} isSecure={false} />
				}
			/>

			{ process.env.NODE_ENV === 'development' &&
				<>
					<Route
						path="/test"
						element={<Test />}
					/>

					<Route
						path="/dev"
						element={<Dev />}
					/>

					<Route
						path="/licensing"
						element={<Licensing />}
					/>
				</>
			}

			<Route
				path="/405"
				element={<Error405 />}
			/>

			<Route
				path="*"
				element={<Error404 />}
			/>
		</Routes>
	);
}

export default RoutesController;
