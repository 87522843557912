import { css, SerializedStyles } from '@emotion/react';
import { IStyles } from '../../context/Styles';

export const imageTilesStyles = (styles: IStyles): SerializedStyles => css`
	display: flex;
	flex-flow: row wrap;
	width: 100%;
`;

export const imageTilesColumnStyles = (styles: IStyles, numCols: number): SerializedStyles => css`
	display: flex;
	flex-flow: column nowrap;
	flex: 0 0 100%;
	flex: 0 0 ${100 / numCols}%;
`;