import React, { createContext } from 'react';
import { useViewportLogic } from '.';

type HookProviderProps = {
	children: React.ReactNode
};

export const HookContext = createContext({
	viewportWidth: 0,
	viewportHeight: 0
});

export const HookProvider: React.FunctionComponent<HookProviderProps> = ({ children }) => {
	const { viewportWidth, viewportHeight } = useViewportLogic();

	return (
		<HookContext.Provider
			value={{
				viewportWidth,
				viewportHeight
			}}
		>
			{ children }
		</HookContext.Provider>
	  );
};
