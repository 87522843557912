import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { DynamoDB } from 'aws-sdk'
import { HookProvider } from './hooks';
import { StylesContext } from './context/StylesContext';
import Styles, { Theme } from './context/Styles';
import { RoutesController } from "./components/RoutesController";
import { Loader } from "./components/Loader";
import './App.css';

export type TRouteData = {
	routeId: string;
	routeName: string;
	routeDescription: string;
	routeType: string;
	routeData: string;
};

const App = () => {
	const [isPageReady, setPageReady] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(true);
	const [error, setErrors] = useState<string>('');
	const [routes, setRoutes] = useState<TRouteData[]>();
	const [styles, setStyles] = useState(Styles);

	// TODO: move this to an API layer
	const fetchRoutes = () => {
		fetch(`https://api.malyaris.com/routes`)
			.then((response) => response.json())
			.then((data) =>
				(async () => {
					await new Promise(resolve => setTimeout(resolve, 1000));
					setLoading(false);
					setRoutes(data.Items.map((item: any) =>
						DynamoDB.Converter.output({ 'M': item }))
					);
				})()
			)
			.catch((error) => {
				setLoading(false)
				setErrors(error)
			});
	};

	useEffect(() => {
		/*
		* Normally timeout would be an anti-pattern. We're using it here because
		* from a programatic perspective we *are* done loading, but the browser
		* still has high resource usage while it unpacks and finalises rendering.
		* We aren't trying to wait for load, we're trying to wait for resources.
		* There's no more specific way to target this, so I've defaulted to this.
		*/
		document.readyState === `complete`
			&& !isLoading
			&& setTimeout(() => {
				setPageReady(true)
			}, 1000);
	}, [isLoading, document.readyState]);

	useEffect(() => {
		fetchRoutes();
	}, []);

	const setTheme = (theme: Theme) => {
		let _styles = {
			...styles,
			theme: theme
		};

		setStyles(_styles);
	};

	return (
		<HookProvider>
			<StylesContext.Provider value={{ styles, setTheme }}>
				<BrowserRouter>
					{
						error && (
							<>
								<h1>An error ocurred.</h1>
								<p>{error || 'Unknown Error'}</p>
								<p>Please try again later.</p>
							</>
						)
					}

					{
						!error &&
						!isPageReady && (
							<Loader />
						)
					}

					{
						!error &&
						isPageReady &&
						routes && (
							<RoutesController routes={routes} />
						)
					}
				</BrowserRouter>
			</StylesContext.Provider>
		</HookProvider>
	);
}

export default App;
