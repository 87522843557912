import { createContext, useContext } from 'react';
import Styles, { Theme, IStyles } from './Styles';

export type StylesContextType = {
    styles: IStyles;
    setTheme: (Theme: Theme) => void;
};

const defaultStylesContext = {
	styles: {
		...Styles,
		theme: Theme.Light,
	},
	setTheme: () => null
}

export const StylesContext = createContext<StylesContextType>(defaultStylesContext);
export const useTheme = () => useContext(StylesContext);
